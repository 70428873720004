<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path class="st0" d="M46,1.5h-9.3c-2.7,0-4.9,2.2-4.9,4.9v51.1c0,2.7,2.2,4.9,4.9,4.9h15.6c5.3,0,9.6,4.3,9.6,9.6v15   c0,5.7,3.7,10.5,8.8,12.1v97.8c0,0.9,0.7,1.6,1.6,1.6h4.3c0.9,0,1.6-0.7,1.6-1.6V99.1c5.2-1.6,9-6.4,9-12.2V42.8   C87.3,20,68.8,1.5,46,1.5z"/>
      <path class="st0" d="M2.4,18.1c0,4.3,0,14.8,0,19.4c0,4.6-0.3,15.3,8.9,16.7c9.2,1.4,12,4.4,12,4.4V3.9c0,0-3.8,3.1-10.5,4.1   C6.1,9.1,2.4,13.8,2.4,18.1z"/>
      <path class="st0" d="M163.3,1.5H154c-22.8,0-41.3,18.5-41.3,41.3V87c0,5.7,3.8,10.6,9,12.2v97.8c0,0.9,0.7,1.6,1.6,1.6h4.3   c0.9,0,1.6-0.7,1.6-1.6V99.1c5.1-1.6,8.8-6.4,8.8-12.1V72c0-5.3,4.3-9.6,9.6-9.6h15.6c2.7,0,4.9-2.2,4.9-4.9V6.4   C168.2,3.7,166,1.5,163.3,1.5z"/>
      <path class="st0" d="M197.6,37.5c0-4.6,0-15.1,0-19.4s-3.8-9-10.5-10c-6.7-1-10.5-4.1-10.5-4.1v54.6c0,0,2.8-3,12-4.4   C198,52.7,197.6,42.1,197.6,37.5z"/>
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconHandsFree',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

